import { contabilistaStore } from '../../store/modules/contabilistas';
import { IContabilistaRequest } from '../../models/IContabilistaRequest';
import { defineComponent, ref, reactive, watchEffect } from 'vue';
import { DxForm, DxSimpleItem, DxRequiredRule, DxEmailRule, DxCustomRule, DxCompareRule, DxButtonItem, DxButtonOptions, DxGroupItem } from 'devextreme-vue/ui/form';
import eventBus from '../../helpers/event-bus';
import Validators from '../../helpers/validators-input';

export default defineComponent({
  name: 'FormContabilista',
  props: {
    propsComponent: {
      required: true,
      type: Object as () => IContabilistaRequest
    }
  },
  setup(props: any) {
    
    const form = ref()
    const popup = eventBus.usePopup()
    const store = contabilistaStore()
    const state = reactive({
      contabilista: props.propsComponent,
      isNew: true
    })
    
    watchEffect(() => {
      if (state.contabilista.id !== 0) {
        state.isNew = false;
      }
    })

    const saveButtonProperties = {
      text: 'Salvar',
      type: 'default',
      stylingMode: 'text',
      icon: 'mdi mdi-content-save',
      useSubmitBehavior: true,
    }

    const cancelButtonProperties = {
      text: 'Cancelar',
      type: 'danger',
      stylingMode: 'text',
      onClick: () => popup.close()
    }

    const masks = {
      cpf: '000.000.000-00',
      cnpj: '00.000.000/0000-00'
    }

    const cnpjCpfOptions = {
      readOnly: state.contabilista.isReadonly,
      valueChangeEvent: 'keyup',
      mask: masks.cpf,
      onKeyDown: setMask,
    }

    function setMask(args: any) {
      const value = args.component.option('value')
      const mask = value.toString().length > 10 ? masks.cnpj : masks.cpf;
      args.component.option('mask', mask);
    }

    function validateCnpjCpf(e: any) {
      const value = e.value
      return Validators.valida_cpf_cnpj(value)
    }

    function ConfirmPassword() {
      return state.contabilista.senhaUsuario
    }

    return {
      form,
      store,
      state,
      saveButtonProperties,
      cancelButtonProperties,
      cnpjCpfOptions,
      validateCnpjCpf,
      ConfirmPassword
    }
  },
  components: {
    DxForm,
    DxCustomRule,
    DxEmailRule,
    DxRequiredRule,
    DxSimpleItem,
    DxButtonItem,
    DxButtonOptions,
    DxGroupItem,
    DxCompareRule,
  }
})