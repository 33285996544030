<template>
  <form @submit.prevent="store.SUBMIT_FORM(state.contabilista)">
    <DxForm
      ref="form"
      :form-data="state.contabilista"
      :show-colon-after-label="true"
      :col-count-by-screen="{ lg: 2, md: 2, sm: 1, xs: 1 }"
    >
      <DxSimpleItem data-field="id" :visible="false" />

      <DxSimpleItem
        data-field="razaoSocial"
        :editor-options="{ readOnly: state.contabilista.isReadonly }"
        :label="{ text: 'Razão Social' }"
      >
        <DxRequiredRule />
      </DxSimpleItem>

      <DxSimpleItem
        data-field="nomeFantasia"
        :editor-options="{ readOnly: state.contabilista.isReadonly }"
      >
        <DxRequiredRule />
      </DxSimpleItem>

      <DxSimpleItem
        data-field="cnpjCpf"
        :label="{ text: 'CNPJ/CPF' }"
        :editor-options="cnpjCpfOptions"
      >
        <DxRequiredRule />
        <DxCustomRule
          message="Documento inválido"
          type="custom"
          :reevaluate="true"
          :validation-callback="validateCnpjCpf"
        ></DxCustomRule>
      </DxSimpleItem>

      <DxSimpleItem
        data-field="email"
        :editor-options="{ readOnly: state.contabilista.isReadonly }"
      >
        <DxRequiredRule />
        <DxEmailRule />
      </DxSimpleItem>

      <DxSimpleItem
        data-field="senhaUsuario"
        :label="{ text: 'Senha' }"
        :editor-options="{
          mode: 'password',
          readOnly: state.contabilista.isReadonly,
        }"
        :visible="state.isNew"
      >
        <DxRequiredRule />
      </DxSimpleItem>

      <DxSimpleItem
        data-field="confirmSenha"
        :label="{ text: 'Confirmar Senha' }"
        :editor-options="{
          mode: 'password',
          readOnly: state.contabilista.isReadonly,
        }"
        :visible="state.isNew"
      >
        <DxRequiredRule />
        <DxCompareRule :comparison-target="ConfirmPassword" />
      </DxSimpleItem>

      <DxSimpleItem
        data-field="telefone"
        :editor-options="{
          mask: '(00) 0000-0000',
          readOnly: state.contabilista.isReadonly,
        }"
      />

      <DxSimpleItem
        data-field="ativo"
        :label="{ text: 'Ativo ?' }"
        editor-type="dxCheckBox"
      />

      <DxSimpleItem item-type="empty" />

      <DxSimpleItem item-type="group" :col-count="2" :col-span="2">
        <DxButtonItem :button-options="saveButtonProperties"></DxButtonItem>
        <DxButtonItem :button-options="cancelButtonProperties"></DxButtonItem>
      </DxSimpleItem>
    </DxForm>
  </form>
</template>
<script lang="ts" src="./index.ts"></script>

<style lang="postcss">
.dx-icon {
  font-size: 14px !important;
}
</style>